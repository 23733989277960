@import "bootstrap";
@import "_bootstrap-datetimepicker";
@import "slick.less"; // Slick carousel
@import "slick-theme.less"; // Slick carousel
@import (inline) "dropdown.min.css"; // Semantic-UI dropdown
@import (inline) "transition.min.css"; // Semantic-UI dropdown
@import (inline) "search.min.css"; // Semantic-UI dropdown
@import "fontastic";

@brand-color-secondary: #9cc13f;
@brand-font-light: "FuturaW01-LightCondense_774878", sans-serif;
@brand-paragraph: "Open Sans", sans-serif;
// New Colors 2022
@leaf: #2b5d23;
@apple: #942020;
@gray: #3c3b3a;
@black: #000000;
@white: #ffffff;
@secondary-apple-light: #e08a87;
@secondary-apple-dark: #c5494c;
@secondary-apple-shade: #fdf0ef;
@secondary-leaf-light: #cedd83;
@secondary-leaf-dark: #718829;
@secondary-leaf-shade: #f7f9ea;
@secondary-earth-light: #e7dbce;
@secondary-earth-dark: #73521d;
@secondary-earth-shade: #f9f6f3;

// New fonts 2022
@display-font: "DM Serif Display", serif;
@sans-font: "Roboto Condensed", sans-serif;

// Spacing
@container-spacing-mobile: 1.2rem;
@container-spacing: 2.4rem;

// Bootsrap overrides
@icon-font-path: "/fonts/";
@font-family-sans-serif: @sans-font;
@font-family-serif: @display-font;
@brand-font: @sans-font;
@table-bg-accent: @secondary-earth-shade;
@text-color: @gray;
@container-tablet: 100%;
@container-sm: 100%;
@container-desktop: 100%;
@container-md: 100%;
@container-large-desktop: 100%;
@container-lg: 100%;
@headings-line-height: 1.3;
@line-height-base: 1.5;
@state-danger-bg: @secondary-apple-shade;
@state-danger-text: @apple;
@state-success-bg: @secondary-leaf-shade;
@state-success-text: @leaf;
@state-info-bg: @secondary-earth-shade;
@state-info-text: @secondary-earth-dark;
@state-warning-bg: @secondary-earth-shade;
@state-warning-text: @secondary-earth-dark;
@well-bg: @secondary-earth-shade;
@well-border: @secondary-earth-shade;
@alert-success-border: @alert-success-bg;
@alert-info-border: @alert-info-bg;
@alert-warning-border: @alert-warning-bg;
@alert-danger-border: @alert-danger-bg;

@media (max-width: 768px) {
   .hidden-xs {
      display: none !important;
   }
}
@media (min-width: 769px) {
   .col-sm-1,
   .col-sm-2,
   .col-sm-3,
   .col-sm-4,
   .col-sm-5,
   .col-sm-6,
   .col-sm-7,
   .col-sm-8,
   .col-sm-9,
   .col-sm-10,
   .col-sm-11,
   .col-sm-12 {
      float: left !important;
   }
}

// Equal height slides
.slick-track {
   display: flex !important;
}
.slick-slide {
   height: auto;
}
.offer-card {
   height: 100%;
}

@keyframes loading-icon {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}

/* Utilities - Start */
.m-0 {
   margin: 0 !important;
}
.mt-0 {
   margin-top: 0 !important;
}
.mr-0 {
   margin-right: 0 !important;
}
.mb-0 {
   margin-bottom: 0 !important;
}
.ml-0 {
   margin-left: 0 !important;
}
.mt-1 {
   margin-top: 0.4rem !important;
}
.mt-2 {
   margin-top: 0.8rem !important;
}
.mt-3 {
   margin-top: 1.2rem !important;
}
.mt-4 {
   margin-top: 1.6rem !important;
}
.mb-5 {
   margin-bottom: 5em !important;
}
.mt-6 {
   margin-top: 2.4rem !important;
}
.mt-12 {
   margin-top: 4.8rem !important;
}
.text-6xl {
   font-size: 32px;
   @media screen and (min-width: 769px) {
      font-size: 48px;
   }
}
.text-5xl {
   font-size: 29px;
   @media screen and (min-width: 769px) {
      font-size: 45px;
   }
}
.text-4xl {
   font-size: 26px;
   @media screen and (min-width: 769px) {
      font-size: 32px;
   }
}
.text-3xl {
   font-size: 26px;
}
.text-2xl {
   font-size: 24px;
}
.text-xl {
   font-size: 18px;
}
.text-lg {
   font-size: 16px;
}
.text-base {
   font-size: 14px;
}
.text-sm {
   font-size: 12px;
}
.text-right {
   text-align: right;
}
.text-center {
   text-align: center;
}
.text-left {
   text-align: left;
}
.text-leaf {
   color: @leaf;
}
.nowrap {
   white-space: nowrap;
}
.text-secondary-leaf-dark {
   color: @secondary-leaf-dark;
}
.container-desktop {
   overflow: hidden;
   @media screen and (min-width: 769px) {
      overflow: initial;
      .container();
      padding: 0 @container-spacing;
   }
}
.align-middle * {
   vertical-align: middle !important;
}
.align-bottom * {
   vertical-align: bottom !important;
}
.overflow-x-mobile {
   @media screen and (max-width: 767px) {
      overflow-x: auto;
      max-width: 100%;
   }
}
.no-letter-spacing {
   letter-spacing: 0em;
}
.overflow-x-auto {
   overflow-x: auto;
}
body.confirm .hidden-confirm {
   display: none;
}
.visible-confirm {
   display: none;
}
body.confirm .visible-confirm {
   display: initial;
}
/* Utilities - End */

/* Mixins - Start */
.display-font {
   font-family: @display-font;
   text-transform: none;
   letter-spacing: 0em;
   font-weight: 400;
   font-kerning: normal;
}
/* Mixins - End */

/* Login - Start */
.login {
   background: @secondary-earth-shade;
}
.login h1 {
   .text-5xl();
   span {
      .text-6xl();
      .display-font();
      color: @leaf;
   }
}
.login form {
   display: flex;
   flex-direction: column;
   button {
      align-self: center;
   }
}
/* Login - End */

/* Backdrop - Start */
.popup-backdrop {
   background: rgba(0, 0, 0, 0.35);
   position: fixed;
   top: 0;
   left: 0;
   width: 100vw;
   height: 100vh;
   opacity: 0;
   z-index: 10;
   transition: opacity 0.2s linear;
   pointer-events: none;
   @media screen and (min-width: 769px) {
      display: none;
      .fasten-notice & {
         display: block;
      }
      &.rek-content {
         display: block;
      }
   }
   &.open {
      opacity: 1;
      pointer-events: auto;
   }
}
/* Backdrop - End */

/* Popup card - Start */
.popup-content {
   @media screen and (min-width: 769px) {
      display: none;
      &.rek-content {
         display: block;
      }
      .fasten-notice &.open {
         display: block;
         .popup-content-inner {
            bottom: auto;
            top: 50%;
            left: 50%;
            width: 400px;
            border-radius: 1.2rem;
            transform: translate(-50%, -50%);
            p {
               font-size: 1.6rem;
            }
            .button {
               width: auto;
            }
         }
      }
   }
   &.open .popup-content-inner {
      bottom: 50%;
      transform: translateY(50%) translateX(-50%);
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.24);
   }
   .popup-content-inner {
      cursor: default;
      font-size: 14px;
      position: fixed;
      bottom: 0;
      left: 50%;
      width: 100vw;
      max-height: 99vh;
      background: #fff;
      border-radius: 1.2rem 1.2rem 1.2rem 1.2rem;
      padding: 2.4rem 2.4rem 3.2rem 2.4rem;
      z-index: 1001;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.24);
      transition: transform 0.2s linear 0.05s,
         bottom 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.15s,
         box-shadow 0.2s linear 0.15s;
      transform: translateY(100%) translateX(-50%);
      overflow-y: auto;
      button + button {
         margin-top: 1.6rem;
      }
   }
   .popup-fields {
      & > div {
         display: flex;
         align-items: center;
         margin-top: 2rem;

         > *:first-child {
            min-width: 25vw;
         }
      }
   }
}
/* Popup card - End */

.include-desktop .popup-backdrop,
.include-desktop .popup-content {
   display: block;
   .fasten-notice &.open {
      .popup-content-inner {
         max-width: 570px;
         right: 0;
         left: unset;
      }
   }
}

/* Header - Start */
body > header {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 1.8rem @container-spacing-mobile;
   @media screen and (min-width: 769px) {
      padding: @container-spacing;
   }
   a {
      display: block;
   }
   .logo {
      width: auto;
      height: 37px;
      display: block;
   }
   .menu {
      .open-menu,
      .close-menu {
         padding: 0;
         margin: 0;
         background: transparent;
         border-width: 0;
         svg {
            display: block;
         }
         @media screen and (min-width: 769px) {
            display: none;
         }
      }
      .close-menu {
         position: absolute;
         top: 2.4rem;
         right: 1.2rem;
      }
      .nav {
         position: fixed;
         top: 0;
         right: 0;
         transform: translateX(100%);
         background: #fff;
         padding: 2.4rem 1.2rem 3.2rem 2.4rem;
         z-index: 20;
         min-width: 248px;
         height: 100%;
         transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;
         &.popup-content {
            display: block;
         }
         @media screen and (min-width: 769px) {
            position: static;
            transform: translateX(0%);
            padding: 0;
            min-width: none;
         }
         &.open {
            transform: translateX(0%);
         }
         img {
            position: absolute;
            bottom: 3.2rem;
            left: 50%;
            width: auto;
            height: 153px;
            transform: translateX(-50%);
            @media screen and (min-width: 769px) {
               display: none;
            }
         }
         nav {
            padding-top: 4.6rem;
            display: flex;
            flex-direction: column;
            @media screen and (min-width: 769px) {
               padding-top: 0;
               flex-direction: row;
               align-items: center;
            }
            > * + * {
               margin-top: 2.4rem;
               @media screen and (min-width: 769px) {
                  margin-top: 0;
                  margin-left: 3.2rem;
               }
            }
         }
         a {
            color: @gray;
            .text-xl();
            &:hover {
               text-decoration: none;
               color: @black;
            }
         }
      }
   }
}
/* Header - End */

/* Buttons - Start */
.button-grid {
   display: grid;
   grid-template-columns: repeat(2, minmax(0, 1fr));
   gap: 1.2rem;
   @media screen and (min-width: 769px) {
      display: flex;
      flex-wrap: wrap;
   }
}
.button-on-mobile {
   cursor: pointer;
   @media screen and (min-width: 769px) {
      cursor: default;
   }
}
button {
   border-width: 0;
   margin: 0;
   padding: 0;
   background: transparent;
}
.button {
   border-width: 0;
   margin: 0;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 1.8rem;
   border-radius: 4px;
   text-decoration: none;
   &:hover,
   &:active,
   &:focus {
      text-decoration: none;
   }
   svg {
      color: @secondary-leaf-light;
      display: block;
      &.loading-icon {
         display: none;
      }
   }
   svg + span {
      margin-left: 0.8rem;
   }
   span {
      .text-xl();
      line-height: 2rem;
      font-weight: 400;
   }
   &.primary {
      background: @leaf;
      color: @white;
      svg {
         color: @secondary-leaf-light;
      }
   }
   &.secondary {
      background: @secondary-leaf-light;
      color: @leaf;
      svg {
         color: @leaf;
      }
   }
   &.danger {
      background: @apple;
      color: white;
      svg {
         color: white;
      }
   }
   &.rounded {
      &.small {
         padding: 0.8rem;
      }
      border-radius: 500px;
   }
   &.small {
      width: auto;
      display: inline-flex;
      padding: 0.8rem 1.2rem;
      justify-content: flex-start;
      svg + span {
         margin-left: 0.6rem;
      }
      span {
         .text-base();
         font-weight: 700;
      }
   }
   &.loading {
      opacity: 0.5;
      .non-loading-icon {
         display: none;
      }
      .loading-icon {
         display: block;
         animation: loading-icon 0.75s infinite linear;
      }
   }
}
#returnOrderTable input[type="file"]::-webkit-file-upload-button {
   background: @secondary-leaf-light;
   color: @leaf;
   border-width: 0;
   margin: 0 0.8rem 0 0;
   border-radius: 4px;
   text-decoration: none;
   padding: 0.8rem 1.2rem;
   .text-base();
   font-weight: 700;
}
/* Buttons - End */

/* Article Card - Start */
.article-card {
   background: @secondary-earth-light;
   border-radius: 5px;
   overflow: hidden;
   .article-card-image {
      position: relative;
      padding-bottom: 56.25%;
   }
   img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
   }
   .article-card-content {
      padding: 2rem;
   }
   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      .display-font();
      color: @apple;
      margin: 0 0 0.8rem 0;
   }
}
/* Article Card - End */

.product-popup .popup-content-inner .product-popup-header {
   display: flex;
   justify-content: space-between;
   > * + * {
      margin-left: 1.8rem;
   }
   > div {
      display: flex;
      align-items: flex-start;
   }
   button {
      margin-top: 0;
      + button {
         margin-left: 1.2rem;
      }
   }
}
.product-field-row {
   display: grid;
   grid-template-columns: 55px 69px 60px 1fr;
   gap: 1.2rem;
   align-items: center;

   & + .product-field-row {
      margin-top: 1rem;
   }
}
/* Offer Card - Start */
.offer-card {
   position: relative;
   background: @secondary-earth-light;
   border-radius: 5px;
   overflow: hidden;
   .offer-header {
      display: flex;
      > * + * {
         margin-left: 1rem;
      }
   }
   .offer-image {
      position: relative;
      width: 48px;
      height: 48px;
      top: 5px;
      flex: 0 0 auto;
   }
   img {
      position: absolute;
      background-color: @secondary-earth-light;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
   }
   .offer-card-content {
      padding: 1.6rem;
   }
   .offer-title {
      color: @leaf;
      font-size: 2.2rem;
   }
   .offer-unit {
      position: absolute;
      display: flex;
      align-items: center;
      flex-direction: column;
      bottom: 0;
      right: 0;
      padding: 0.8rem 2rem;
      font-size: 12px;

      .price {
         color: @apple;
         font-weight: bold;
         font-size: 36px;
         font-family: "DM Serif Display", serif;
      }
      .inactive .price {
         color: rgb(128, 128, 128);
         text-decoration: line-through;
         font-weight: normal;
         font-size: inherit;
         font-family: "Roboto Condensed", sans-serif;
      }
   }

   .offer-pack {
      color: @secondary-earth-dark;
   }
   .offer-price {
      del {
         color: @secondary-apple-light;
      }
   }
   .offer-input {
      input {
         text-align: center;
      }
   }
   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      .display-font();
      color: @apple;
      margin: 0 0 0.2rem 0;
   }
}
/* Offer Card - End */

/* Fasten - Start */
.fasten-title-container {
   display: flex;
   align-items: center;
   justify-content: space-between;
   @media screen and (min-width: 769px) {
      margin-top: 4.8rem;
   }
}
.fasten-title {
   margin-top: 2.4rem;
   margin-bottom: 1.2rem;
   .text-4xl();
   @media screen and (min-width: 1025px) {
      margin-bottom: 1.6rem;
      margin-top: 0;
   }
}
.fasten-mobile {
   > button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.4rem @container-spacing-mobile;
      &:nth-of-type(odd) {
         background: @secondary-earth-shade;
      }
      > div {
         display: flex;
         align-items: center;
         justify-content: flex-start;
         flex: 0 1 auto;
         max-width: 75%;
      }
      .product-name {
         .text-lg();
         text-overflow: ellipsis;
      }
      .product-price {
         flex: 0 0 auto;
      }
      .image-container {
         flex: 0 0 auto;
         width: 28px;
         height: 39px;
         margin-right: @container-spacing-mobile;
         position: relative;
         background: @secondary-earth-light;
         img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
         }
      }
   }
}
.fasten-popup,
.rek-popup .product-popup {
   h3 {
      .display-font();
      color: @leaf;
      .text-3xl();
      margin: 0;
   }
   dl {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 0.8rem;
      margin-bottom: 2.4rem;
      div {
         display: flex;

         align-items: center;
         dt {
            margin-right: 0.8rem;
            &::after {
               content: ":";
            }
         }
      }
   }
   button {
      margin-top: 2.4rem;
   }

   button + button {
      margin-top: 2.4rem !important;
   }
}
.fasten-dates {
   display: grid;
   grid-template-columns: 70px 60px 1fr;
   gap: 1.2rem;
   align-items: center;
   input {
      text-align: center;
   }
   & + .fasten-dates {
      margin-top: 1.2rem;
   }
}
.fasten-table {
   .table {
      margin-bottom: 0;
   }
}
.fasten-submit-order {
   margin-top: 1.2rem;
   > * + * {
      margin-top: 1.2rem;
   }
   @media screen and (min-width: 769px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > * + * {
         margin-top: 0rem;
      }
   }
   p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 1.5rem;
   }
}
.fasten-order-wrapper {
   display: flex;
   flex-direction: column;
   max-width: 170px;
}
.fasten-order-wrapper > button {
   width: fit-content;
   place-self: flex-end;
   &.disabled {
      opacity: 0.5;
      pointer-events: none;
   }
}
.fasten-order-wrapper > span {
   text-align: right;
}
.fasten-button-wrapper {
   display: flex;
   & > button:last-child {
      margin-left: 1.2rem;
   }
}
/* Fasten - End */

/* Home grid - Start */
.home-grid {
   margin-top: 2.4rem;
   @media screen and (min-width: 769px) {
      margin-top: 4.8rem;
   }
   .article-card-content {
      h2 {
         .text-3xl();
      }
      p {
         .text-base();
      }
   }
   @media screen and (min-width: 769px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 3.2rem;
      padding: 0 @container-spacing;
      margin-bottom: 4rem;
   }
   @media screen and (min-width: 1025px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      > *:nth-child(4n + 4) {
         grid-column: span 3;
         display: flex;
         .article-card-image {
            flex: 0 0 31.9%; // 31.2 -> 31.9
            padding-bottom: 0;
            min-height: 400px;
         }
         .article-card-content {
            padding: 4.4rem 3.2rem;
            h2 {
               font-size: 32px;
            }
            p {
               .text-xl();
            }
         }
      }
   }
}
.calendar {
   margin-bottom: 2.4rem;
   .bootstrap-datetimepicker-widget {
      padding-bottom: 2.4rem;
   }
   input[type="text"] {
      display: none;
   }
}
/* Home grid - End */

/* Datepicker - Start */
.bootstrap-datetimepicker-widget .datepicker .datepicker-months,
.bootstrap-datetimepicker-widget .datepicker .datepicker-years,
.bootstrap-datetimepicker-widget .datepicker .datepicker-decades {
   table td span {
      border-radius: 5000px;
      &.active {
         color: @leaf !important;
         background: @secondary-leaf-light !important;
      }
   }
}
.bootstrap-datetimepicker-widget .datepicker .datepicker-days {
   table.table-condensed {
      display: block;
      width: 100%;
      thead,
      tbody,
      tfoot {
         display: block;
      }
      tr {
         display: grid;
         grid-template-columns: repeat(7, minmax(0, 1fr));
         gap: 2%;
         @media screen and (min-width: 769px) {
            gap: 3%;
         }
         @media screen and (min-width: 1025px) {
            gap: 5%;
         }
      }
      thead > tr:nth-child(1) th:nth-child(2) {
         grid-column: span 5;
      }
      td,
      th {
         width: auto;
         height: auto;
         &.picker-switch {
            width: auto;
            text-transform: capitalize;
         }
      }
      .day,
      .day.today {
         border-radius: 5000px;
         font-variant-numeric: tabular-nums;
         padding: 30% 0;
         display: flex;
         justify-content: center;
         align-items: center;
         @supports (aspect-ratio: 1) {
            aspect-ratio: 1;
            padding: 0;
         }
         &::before {
            display: none;
         }
      }
      .active {
         color: @leaf !important;
         background: @secondary-leaf-light !important;
      }
   }
}
/* Datepicker - End */

/* Welcome - Start */
.welcome {
   .text-5xl();
   span {
      .display-font();
      .text-6xl();
      color: @leaf;
   }
   text-align: center;
   line-height: 1.4;
   margin-top: 0.5rem;
   margin-bottom: 0.2px;
}
/* Welcome - End */

/* Bootstrap override classes - Start */
.well {
   box-shadow: none;
}
h1,
.h1 {
   .text-6xl();
}
h2,
.h2 {
   .text-4xl();
}
h3,
.h3 {
   .text-3xl();
}
h4,
.h4 {
   .text-2xl();
}
h5,
.h5 {
   .text-xl();
}
h6,
.h6 {
   .text-lg();
}
.table.aroma-table > thead > tr > th,
.table.aroma-table > tbody > tr > th,
.table.aroma-table > tfoot > tr > th,
.table.aroma-table > thead > tr > td,
.table.aroma-table > tbody > tr > td,
.table.aroma-table > tfoot > tr > td {
   border-width: 0;
   line-height: 1.9rem;
   vertical-align: middle;
   padding: 1.4rem 0.6rem;
   .text-lg();
   &:first-child,
   &.mobile-first {
      padding-left: 1.2rem;
   }
   &:last-child,
   &.mobile-last {
      padding-right: 1.2rem;
   }
   @media screen and (min-width: 769px) {
      padding: 1.4rem 1.2rem;
   }
}
.table.aroma-table > thead > tr > th {
   background: @secondary-leaf-light;
   color: @leaf;
}
.table.aroma-table > tbody > tr > th {
   color: @leaf;
   font-weight: 400;
}
.table.aroma-table > thead.sticky {
   position: sticky;
   top: 0;
   z-index: 10;
}
.label {
   font-weight: 700;
   .text-sm();
   padding: 0.7rem 1.2rem;
   border-radius: 50px;
   color: @leaf;
   background-color: @secondary-leaf-light;
}
.input-group-addon {
   border-color: @secondary-earth-light;
   background-color: @secondary-earth-shade;
   color: @secondary-earth-dark;
}
/* Bootstrap override classes - End */

/* Carousel - Start */
#home-slider {
   margin-top: 2.4rem;
   @media screen and (min-width: 769px) {
      margin-top: 4.8rem;
   }
}
#offer-slider,
#donotforget-slider {
   margin-top: 1.4rem;
   margin-left: 1.4rem;
   margin-bottom: 6.2rem;
   @media screen and (min-width: 769px) {
      margin-top: 2.4rem;
      margin-left: 2.4rem;
   }
}
.slider-container {
   overflow: hidden;
}
.slider-heading {
   margin-bottom: 0;
   margin-top: 1.2rem;
   .text-2xl();
   @media screen and (min-width: 769px) {
      .text-4xl();
      margin-top: 2.4rem;
   }
}
.slick-slider {
   margin-bottom: 12rem;
}
.slick .slick-slide > * {
   margin: 0 0.6rem;
   @media screen and (min-width: 769px) {
      margin: 0 1.2rem;
   }
}
.slick-track {
   margin-left: -0.6rem;
   @media screen and (min-width: 769px) {
      margin-left: -1.2rem;
   }
}
.slick-dots {
   height: 0.8rem;
   li {
      width: 0;
      height: 0;
      + li {
         margin-left: 0.9rem;
      }
      button {
         width: 0.8rem;
         height: 0.8rem;
         border-radius: 50%;
         background: rgba(0, 0, 0, 0.2);
         &::before {
            content: none;
         }
      }
      &.slick-active button {
         background: @gray;
      }
   }
}

/* Carousel - End */

/* Scroll-up - start */
#scrollup {
   position: fixed;
   bottom: 7.2rem;
   right: 1.6rem;
   z-index: 8;
   background: @secondary-earth-shade;
   border-radius: 50%;
   padding: 1.6rem;
   opacity: 0;
   pointer-events: none;
   color: @secondary-earth-dark;
   transition: opacity 0.3s linear;
   &.visible {
      opacity: 1;
      pointer-events: auto;
   }
   svg {
      display: block;
   }
   @media screen and (min-width: 769px) {
      right: 3.2rem;
   }
}
/* Scroll-up - End */

/* GLOBAL */
body {
   position: relative;
   -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: @brand-font;
   font-weight: 700;
   text-transform: uppercase;
   color: @secondary-leaf-dark;
   letter-spacing: 0.04em;
   font-kerning: none;
}

input,
button {
   outline: none !important;
   box-shadow: none !important;
}

input.form-control,
textarea.form-control {
   border: 1px solid @secondary-earth-light;
   font-weight: 400;
   padding: 0.4rem 1.2rem;
   &:focus {
      border: 1px solid @secondary-earth-dark;
      box-shadow: none;
   }
   &[type="number"] {
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button {
         -webkit-appearance: none;
         margin: 0;
      }
   }
}

.container {
   padding: 0 @container-spacing-mobile;
   @media screen and (min-width: 769px) {
      padding: 0 @container-spacing;
   }
}

.alert {
   margin-bottom: 1.2rem;
   text-align: center;
   h1,
   h2,
   h3,
   h4,
   h5,
   h6 {
      text-transform: none;
      font-size: 1.8rem;
      margin: 0;
      color: @secondary-earth-dark;
   }
   p {
      max-width: 400px;
      margin: 0 auto;
   }
   &.alert-danger {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
         color: @apple;
      }
   }
}

article {
   section {
      background: #fff;
      margin-bottom: 30px;
      &.main {
         padding: 60px 30px;
         margin-bottom: 60px;
      }
   }
}

.cf:before,
.cf:after {
   content: " "; /* 1 */
   display: table; /* 2 */
}

.cf:after {
   clear: both;
}

.popover {
   max-width: 700px;
}

.popup {
   z-index: -1;
   opacity: 0;
   transition: opacity 0.2s, transform 0.2s;
   background: #dddcd9;
   display: block;
   position: absolute;
   width: 100%;
   padding: 5px;
   border-radius: 2px;
   transform: scale(0.95);
   &.active {
      z-index: 1;
      opacity: 1;
      transform: scale(1);
   }
   .input-group {
      height: 35px;
      input {
         border: none;
         padding: 4px 11px !important;
      }
   }
   .togglePopup {
      color: #fff;
      background: #000000;
      padding: 7px 15px;
      display: inline-block;
      border-radius: 0px 3px 3px 0;
      .text-base();
      cursor: pointer;
      font-weight: bold;
   }
}

.table {
   &.table-xs {
      td {
         padding: 4px 8px;
      }
   }
   &.table-border {
      td {
         border-right: 1px solid #bbb;
         border-left: 1px solid #bbb;
      }
   }
}

.no-padding-left {
   padding-left: 0;
}
.no-padding-right {
   padding-right: 0;
}

@keyframes loading {
   0% {
      opacity: 1;
      transform: rotate(0deg);
   }
   100% {
      opacity: 1;
      transform: rotate(360deg);
   }
}
.btn {
   &.btn-confirm {
      &:before {
         display: inline-block;
         content: "\e013";
         color: #fff;
         font-family: "Glyphicons Halflings";
         .text-sm();
         margin-right: 8px;
      }

      &[disabled] {
         background: #999 !important;
         opacity: 1 !important;
         cursor: pointer;
         &:before {
            opacity: 1;
            position: relative;
            display: inline-block;
            content: " ";
            width: 15px;
            height: 15px;
            border-radius: 30px;
            border: 1px dashed #fff;
            margin: -3px 0 -3px 0;
            animation: loading 2s linear infinite;
         }
      }
   }
   &.btn-primary {
      background: #000;
      border: none;
      box-shadow: none;
      outline: none;
      color: #fff;
      margin-right: 10px;
      padding: 9px 15px;
      font-weight: bold;
      transition: background 0.2s;
      &:hover {
         opacity: 0.95;
         background: #000;
      }
      &:active {
         opacity: 0.8;
         background: #000;
      }
      .glyphicon {
         top: 0;
         font-size: 11px;
         padding-right: 5px;
         &.glyphicon-chevron-right {
            padding-left: 5px;
            padding-right: 0;
         }
         &.glyphicon-chevron-left {
            padding-right: 5px;
         }
      }
   }
   &.btn-checkbox {
      padding: 3px 12px;
      background: lighten(@brand-color-secondary, 15%);
      box-shadow: 0 3px 0 0 darken(@brand-color-secondary, 5%);
      transition: top 0.07s, box-shadow 0.07s;
      top: 0;
      border-right: 1px solid lighten(@brand-color-secondary, 7%);
      border-left: 1px solid lighten(@brand-color-secondary, 7%);
      color: #000;
      &.active,
      &:active {
         position: relative;
         top: 2px;
         background: lighten(@brand-color-secondary, 10%);
         box-shadow: 0 1px 0 0 darken(@brand-color-secondary, 5%);
         &:hover {
            background: lighten(@brand-color-secondary, 10%);
         }
      }
      &:hover {
         background: lighten(@brand-color-secondary, 13%);
      }
      &:first-child {
         border-left: none;
      }
      &:last-child {
         border-right: none;
      }
      span {
         //Icon
         position: relative;
         top: 2px;
         &.icon-krav {
            font-size: 17px;
            margin-top: -4px;
            margin-bottom: -4px;
            top: 3px;
            position: relative;
            display: inline-block;
         }
      }
   }
}

.getfixed {
   &.fixed {
      padding-top: 62px;
   }
}

nav {
   &.navbar-default {
      background-color: #000;
      border: none;
      margin-bottom: 0;
      border-radius: 0;
      .container,
      ul {
         background: #000;
      }
      a,
      a:hover {
         color: #fff;
      }
      > .container {
         > ul.nav.navbar-nav {
            float: right;
            margin-right: -20px;
            > li {
               > a {
                  font-family: @brand-font;
                  color: #fff !important;
                  text-transform: uppercase;
                  .text-xl();
                  padding: 20px;
                  transition: background 0.15s;
                  &:hover {
                     background: #333 !important;
                  }
               }
            }
         }
      }
   }
   .logo {
      position: relative;
      top: 3px;
      float: left;
      height: 60px;
      width: auto;
   }
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:focus,
.navbar-default .navbar-nav > .open > a:hover {
   background: #333 !important;
}

.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover {
   background: #383838;
   color: #fff;
}

.table tbody > tr > td.vert-align,
.table.vert-align tbody > tr > td {
   vertical-align: middle;
}

/* ORDER */

#datepicker {
   .disabled {
      opacity: 0.4;
      &.today {
         opacity: 1;
      }
   }
}

#idleWarning {
   display: none;
   position: fixed;
   top: 0;
   left: 0;
   height: 85px;
   width: 100%;
   z-index: 4;
}

#headerControls {
   background: @brand-color-secondary;
   z-index: 3;
   .container {
      background: @brand-color-secondary;
      padding: 0 @container-spacing-mobile;
      @media screen and (min-width: 769px) {
         padding: 0 @container-spacing;
      }
   }
   &.fixed {
      position: fixed;
      width: 100%;
      top: 0;
      @media (max-width: @screen-sm-max) {
         position: relative;
      }
   }
   .title {
      margin-top: 2px;
      h1 {
         line-height: 1;
         margin: 0;
         display: inline-block;
         margin-right: 5px;
         font-family: @brand-font;
         .text-3xl();
         color: #000;
      }
      .date {
         line-height: 1;
         display: inline-block;
         font-family: @brand-font;
         .text-xl();
         color: #9cc13f;
         background: #000;
         padding: 5px 10px;
         margin-left: 3px;
         position: relative;
      }
   }
   .filters {
      .search {
         margin-left: 15px;
         input {
            border: none;
            box-shadow: none;
            outline: none;
            border-radius: 3px;
            height: 30px;
            width: 300px;
         }
      }
   }
}

.tablet-full {
   @media (max-width: 768px) {
      width: 100%;
   }
}

#categoryNav {
   max-height: 100%;
   overflow: auto;
   padding-bottom: 2.4rem;
   @media screen and (min-width: 769px) {
      padding-bottom: 130px;
   }
   .flexed {
      display: flex;
      flex-direction: row;
      padding: 0 1.2rem;
      @media screen and (min-width: 769px) {
         display: block;
         padding: 0;
      }
      .search {
         position: relative;
         flex: 1 0 auto;
         margin-top: 0;
         @media screen and (min-width: 769px) {
            margin-top: 4.5rem;
         }
         svg {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 1.2rem;
            height: 100%;
            width: 13px;
         }
         input {
            padding: 0.8rem 1.2rem 0.8rem 3.7rem;
            height: auto;
            line-height: 2rem;
            @media screen and (min-width: 769px) {
               padding: 1rem 1.2rem 1rem 3.7rem;
            }
         }
      }
      > *:nth-child(2) {
         margin-left: 1.2rem;
         button {
            padding: 0.9rem 1.2rem;
         }
      }
   }
   &.fixed {
      position: fixed;
      top: 0px;
      @media (max-width: @screen-sm-max) {
         z-index: 1000;
         margin-top: 1rem;
      }
      @media (max-width: 768px) {
         .search {
            visibility: hidden;
         }
      }
   }
   #filter {
      @media (max-width: 768px) {
         display: block !important;
      }
   }
   .filter-nav {
      position: fixed;
      top: 0;
      right: 0;
      transform: translateX(100%);
      background: #fff;
      padding: 2.4rem 1.2rem 3.2rem 2.4rem;
      z-index: 20;
      min-width: 248px;
      height: 100%;
      padding: 7rem 2.4rem;
      overflow: auto;
      transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;
      @media screen and (min-width: 769px) {
         position: static;
         transform: translateX(0%);
         padding: 0;
         min-width: 0;
         overflow: visible;
      }
      &.open {
         transform: translateX(0%);
      }
      .close-menu {
         position: absolute;
         top: 2.4rem;
         right: 1.2rem;
      }
   }
   ul {
      padding: 0;
      margin: 0;
      li {
         list-style: none;
         a {
            color: @secondary-leaf-dark;
            padding: 4px 0;
            &:hover {
               color: @leaf;
               text-decoration: none;
            }
         }
      }
   }
   h2 {
      color: @gray;
      text-transform: none;
      letter-spacing: 0.005em;
   }
   .filter-item {
      display: flex;
      align-items: center;
      padding: 0.2rem 0;
      &:hover span {
         color: @leaf;
      }
      cursor: pointer;
      input {
         display: none;
      }
      span {
         color: @secondary-leaf-dark;
         font-weight: 400;
      }
      svg {
         color: @leaf;
         margin-right: 0.4rem;
      }
      input:checked ~ span {
         font-weight: 700;
         color: @leaf;
         &::after {
            content: " \2713";
         }
      }
   }
}

.form-control {
   padding: 4px;
   box-shadow: none;
   outline: none;
}

#campaigns {
   margin-top: 25px;
   margin-bottom: 15px;
   .campaign {
      background: #fff;
      padding: 15px;
      margin-bottom: 15px;
      border-radius: 3px;
      img {
         margin-bottom: 15px;
         max-width: 100%;
         max-height: 150px;
         border-radius: 3px;
      }
      .input-group {
         margin-bottom: 10px;
      }
      h3 {
         margin-top: 0;
      }
   }
}

#order {
   body.confirm & .col-sm-10 {
      width: 100%;
   }
   // Adjust columns
   .picture {
      padding: 0 3px 0 6px !important;
      width: 15%;
      @media screen and (min-width: 769px) {
         width: 5.5%;
      }
      > * {
         display: block;
         width: 30px;
         height: 30px;
         position: relative;
         /*background: @secondary-earth-light;*/
         img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
         }
      }
   }
   .id {
      padding: 0 3px !important;
      width: 15%;
      @media screen and (min-width: 769px) {
         width: 5%;
      }
   }
   .name {
      padding: 0 0 0 3px !important;
      width: 60%;
      @media screen and (min-width: 769px) {
         width: 26.8333333% !important;
      }
   }
   .country {
      padding: 0 3px !important;
      width: 4% !important;
   }
   .qty_per_parcel {
      width: 6.3333333% !important;
      padding: 0 3px !important;
   }
   .parcels {
      padding: 0 3px !important;
   }
   .qty {
      width: 10.33333333% !important;
      padding: 0 3px !important;
   }
   .aprice,
   .aprice_smallorder {
      padding: 0 3px !important;
      width: 7.33333333% !important;
   }
   .aprice {
      @media (max-width: 767px) {
         display: flex !important;
         color: @leaf;
      }
   }
   .price_trend {
      width: 2% !important;
      padding: 0 1px !important;
   }
   .nettoprice {
      padding: 0 3px !important;
      width: 11% !important;
   }
   .action {
      width: 6% !important;
      padding: 0 6px 0 3px !important;
   }
   //

   #headerRow {
      position: sticky;
      top: 0;
      margin: 0;
      z-index: 1;
      background-color: #fff;
      padding: 14px 0;
      height: 45px;
      font-weight: bold;
      > div {
         padding: 0 6px;
      }
      .aprice.two-rows,
      .aprice_smallorder.two-rows {
         margin-top: -10px;
      }
   }

   .orderForm {
      .text-sm();
      padding-bottom: 100px;

      .orderHead {
         background: #5491c0;
         color: #fff;
         padding: 1.2rem;
         h2 {
            margin: 0;
            margin-bottom: 5px;
         }
         p span {
            display: block;
            margin: 0;
            margin-bottom: 5px;
         }
         input {
            display: block;
            width: 100%;
            padding: 10px;
            border: 0;
            color: #333;
            border-radius: 3px;
         }
      }

      .CategoryRow,
      .orderHead {
         padding: 0.8rem 1.2rem;
         .text-xl();
         color: @secondary-earth-dark;
         background-color: @secondary-earth-light;
         margin: 0;
      }

      .OrderRow {
         margin: 0;
         > span {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            .icon {
               font-size: 13px;
               position: relative;
               top: 2px;
               margin-right: 2px;
               display: inline-block;
               &.icon-krav {
                  font-size: 19px;
                  top: 4px;
                  margin-top: -7px;
               }
            }
         }
         &.warning {
            &.odd {
               background-color: #fff4df !important;
            }
            &.even {
               background-color: #ffedd3 !important;
            }
            .info {
               font-weight: bold;
            }
         }
         &.campaign {
            &.even {
               // background-color: #edf9e8 !important;
               background-color: #b9dbb3 !important;
            }
            &.odd {
               // background-color: #f6fff2 !important;
               background-color: #c5e1c1 !important;
            }
         }
         &.even {
            background-color: @secondary-earth-shade;
         }
         &.odd {
            background-color: #fff;
         }
         .well {
            border: none;
            padding: 0;
            background: none;
         }
      }
      .OrderRow,
      .CategoryRow,
      .HeaderRow {
         height: 46px !important;
         overflow: hidden;
         width: 100%;
      }

      .action {
         text-align: right;
         white-space: nowrap;
         span {
            //Icons
            display: inline-block;
            margin: 10px 0;
            padding: 5px 4px;
            .text-base();
            cursor: pointer;
            line-height: 1;
            color: #333;
            opacity: 1;
            &:hover {
               opacity: 0.8;
            }
            @media (max-width: @screen-md-max) {
               padding: 5px 2px;
            }
         }
      }
      .price {
         color: @apple;
         font-weight: bold;
         text-align: right;
         @media (max-width: 767px) {
            font-size: 12px;
         }
         .text-base();
      }
      .inactive .price {
         color: rgb(128, 128, 128);
         text-decoration: line-through;
         font-weight: normal;
      }
   }
}

#footerControls {
   position: fixed;
   padding: 1.2rem;
   bottom: 0;
   width: 100%;
   background: @secondary-earth-light;
   z-index: 1000;
   display: flex;
   justify-content: space-between;
   align-items: center;
   > div {
      display: flex;
      align-items: center;
      > * + * {
         margin-left: 1.2rem;
      }
   }
   .total {
      color: @secondary-earth-dark;
   }
}

#showorder {
   .brutto-shown,
   .brutto-button-shown {
      display: none;
   }

   .is_campaign {
      color: red !important;
   }
}

/* Emils grejer */

.hide,
.searchHide,
.filterHide {
   display: none;
}

// span.price {
//    font-family: monospace;
// }

span.number {
   word-spacing: -30%;
}

h1,
h2,
h3 {
   span.number {
      word-spacing: -50%;
   }
}

.col-price {
   text-align: right;
}

// Pricelist
#pricelist {
   .text-sm();
   min-width: 1000px;
   .checkbox {
      position: relative;
      width: 10px;
      height: 10px;
      background: #fff;
      border: 1px solid #555;
      float: left;
      margin: 0;
      border-radius: 2px;
   }
   .columnizer-column {
      width: 480px;
      float: left;
      margin-left: 5px;

      .pdf-category {
         font-weight: bold;
         .text-lg();
         line-height: 30px;
         margin-top: 5px;
      }
      .pdf-pricelist-product {
         line-height: 21px;
         border-bottom: 1px solid #eee;
         -webkit-print-color-adjust: exact;
         &.odd {
            background: #eee !important;
         }
         .col-id {
            max-width: 8ex;
         }

         td {
            overflow: hidden;
            padding: 2px;
         }

         td:first-child,
         td:last-child {
            overflow: hidden;
            padding-left: 5px;
            padding-right: 5px;
         }
      }
   }
   .page,
   .columnizer-page {
      page-break-inside: avoid;
   }
}

#pricemodel_table {
   tr.category td {
      background: #2f2f2f;
      color: #fff;
   }
}

.nowrap {
   white-space: nowrap;
}

table.nowrap td {
   white-space: nowrap;
}

#returnOrderTable {
   overflow: hidden;
}

#returnOrderProducts tbody tr {
   cursor: pointer;
}

.rek-file-upload input[type="file"] {
   display: none;
}

.rek-file-upload label {
   cursor: pointer;
}

.rek-image-gallery {
   display: flex;
   flex-wrap: wrap;
}

.rek-image-container {
   position: relative;
   display: inline-block;
   min-width: unset !important;
   overflow: hidden;
   cursor: pointer;
   border: 2px solid #eee;
   border-radius: 10px;
   margin: 0rem 0.25rem;

   .rek-image {
      object-fit: cover;
      width: 100px;
      height: 100px;
   }
   .rek-image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
         border-radius: 50%;
         background: #942020;
         color: white;
         padding: 1.25rem;
         width: 40%;
         height: 40%;
      }
   }
}

.rek-row {
   cursor: pointer;
}

.popup-image {
   width: 80%;
   height: 60%;
   max-width: 769px;
   max-height: 769px;
   display: none;
   background-color: #fff;
   position: fixed;
   bottom: 50%;
   left: 50%;
   z-index: 1002;
   transform: translate(-50%, 50%);
   padding: 2em;
   border-radius: 1.2rem;
   cursor: default;

   .popup-image-close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1em;
      cursor: pointer;
   }

   &.open {
      display: block;
   }

   img {
      width: 100%;
      height: 100%;
      object-fit: contain;
   }
}

.table-striped-parent > tbody > tr.parent {
   background-color: #f9f6f3;
   cursor: pointer;
}

.table-striped-parent > tbody > tr.child {
   background-color: #fff;
}

.table-striped > tbody > tr:nth-of-type(odd) + tr.sibling {
   background-color: #f9f6f3;
}

.table-striped > tbody > tr:nth-of-type(even) + tr.sibling {
   background-color: #fff;
}

a.aroma-link {
   color: #2b5d23;
   text-decoration: underline;
   &:hover {
      text-decoration: none;
   }
}

.rek-icon {
   display: inline-flex;
   width: 20px;
   height: 20px;
   margin-right: 0.5rem;
   svg {
      width: 20px;
      top: 4px;
      position: relative;
   }
}

.rek-button {
   position: fixed;
}

.is-grid {
   display: grid !important;
}

.popup-content-inner {
   max-width: 769px;

   .close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1em;
      cursor: pointer;
   }
}

.rek-item-info {
   .item-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
      .item-label {
         font-weight: bold;
         margin-right: 0.5rem;
      }
      .item-value {
         flex: 1;
      }
   }
}

tr.child.hidden {
   display: none;
}
